<script setup lang="ts">
import { watch } from 'vue'

type Props = {
    title: string,
    content?: string,
    link?: string,
    duration: number,
    toastShowAtAdding: boolean,
    toastShow: boolean,
    class? : string
}

const props = defineProps<Props>()

const emit = defineEmits(['closeToast', 'increaseToastKey'])

watch(() => props.toastShow, () => {
    if(props.toastShow) {
        
        const displayDuration = props.duration || 3000

        setTimeout(() => {
            emit('closeToast', false)
        }, displayDuration)
    }
})
</script>

<template>
    <transition name="fade">
        <div
            v-if="toastShow"
            :class="[ props.class]"
            class="app-toast position-fixed bottom-0"
        >
            <div class="toast-content">
                <div class="toast-header">
                    <strong class="mr-auto">{{ props.title }}</strong>
                    <button
                        type="button"
                        class="ml-2 mb-1 close app-toast-btn"
                        aria-label="Close"
                        @click="emit('closeToast', false)"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="toast-body">
                    {{ toastShowAtAdding ? 'Saved to' : 'Removed from' }}
                    <a
                        id="addedWishlistToastLink"
                        class="font-weight-bold text-break"
                        :href="props.link"
                        target="_blank"
                        rel="nofollow"
                    >
                        {{ props.content }} 
                    </a>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
.app-toast {
    z-index: 1054;
    min-width: 250px;
    margin-bottom: 32px;
    margin-left: 32px;
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 0.25rem;
    outline: none;

    &-btn {
        &:focus {
            outline: none;
        }
    }
}

.toast-body {
    background-color: rgba(255, 255, 255, 0.85);
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 1s ease, transform 1s ease;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
    transform: translateY(20px);
}
.fade-enter-to, .fade-leave-from {
    opacity: 1;
    transform: translateY(0);
}
</style>
